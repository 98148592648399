

$small: 1; 
$medium: 768;
$large: 1025;

$xlarge: 1441;
$xxlarge: 1921;

$small-breakpoint: $medium;
$medium-breakpoint: $large;
$large-breakpoint: $xlarge;

$small-min-width: $small * 1px;
$small-max-width: $medium * 1px - 1px;
$medium-min-width: $medium * 1px;
$medium-max-width: $large * 1px - 1px;
$large-min-width: $large * 1px;
$large-max-width: $xlarge * 1px - 1px;
$xlarge-min-width: $xlarge * 1px;
$xlarge-max-width: $xxlarge * 1px - 1px;
$xxlarge-min-width: $xxlarge * 1px;



$screen: "only screen";

$small-only: "(min-width: #{$small-min-width}) and (max-width: #{$small-max-width})";
$small-up: "#{$screen} and (min-width: #{$small-min-width})";

$medium-only: "#{$screen} and (min-width: #{$medium-min-width}) and (max-width: #{$medium-max-width})";
$medium-up: "#{$screen} and (min-width: #{$medium-min-width})";

$large-only: "(min-width: #{$large-min-width})";
$large-up: "#{$screen} and (min-width: #{$large-min-width})";

$xlarge-only: "#{$screen} and (min-width: #{$xlarge-min-width}) and (max-width: #{$xlarge-max-width})";
$xlarge-up: "#{$screen} and (min-width: #{$xlarge-min-width})";

$xxlarge-only: "#{$screen} and (min-width: #{$xxlarge-min-width})";
$xxlarge-up: "#{$screen} and (min-width: #{$xxlarge-min-width})";

$breakpoint-list: (
  ('small-only',   $small-only),
  ('small-up',     $small-up),
  ('medium-only',  $medium-only),
  ('medium-up',    $medium-up),
  ('large-only',   $large-only),
  ('large-up',     $large-up),
  ('xlarge-only',  $xlarge-only),
  ('xlarge-up',    $xlarge-up),
  ('xxlarge-only', $xxlarge-only),
  ('xxlarge-up',   $xxlarge-up)
);

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$retina: (
  "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
  "#{$screen} and (min--moz-device-pixel-ratio: 2)",
  "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
  "#{$screen} and (min-device-pixel-ratio: 2)",
  "#{$screen} and (min-resolution: 192dpi)",
  "#{$screen} and (min-resolution: 2dppx)"
);
