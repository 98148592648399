

  $font-family-sans-serif: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  $font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
  $font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;


  $font-weight-normal: normal;
  $font-weight-bold: bold;


  $secondary-color: #e7e7e7;
  $primary-color: #008CBA;
  $alert-color: #f04124;
  $success-color: #43AC6A;
  $warning-color: #f08a24;
  $info-color: #a0d3e8;

  $white       : #FFFFFF;
  $ghost       : #FAFAFA;
  $snow        : #F9F9F9;
  $vapor       : #F6F6F6;
  $white-smoke : #F5F5F5;
  $silver      : #EFEFEF;
  $smoke       : #EEEEEE;
  $gainsboro   : #DDDDDD;
  $iron        : #CCCCCC;
  $base        : #AAAAAA;
  $aluminum    : #999999;
  $jumbo       : #888888;
  $monsoon     : #777777;
  $steel       : #666666;
  $charcoal    : #555555;
  $tuatara     : #444444;
  $oil         : #333333;
  $jet         : #222222;
  $black       : #000000;


  $body-bg: $white;
  $body-font-color: $jet;
  $body-font-family: $font-family-sans-serif;
  $body-font-weight: $font-weight-normal;
  $body-font-style: normal;


  $font-smoothing: antialiased;


  $text-direction: ltr;
  $opposite-direction: right;
  $default-float: left;
  $last-child-float: $opposite-direction;


  $global-radius: 3px;
  $global-rounded: 1000px;


  $shiny-edge-size: 0 1px 0;
  $shiny-edge-color: rgba($white, .5);
  $shiny-edge-active-color: rgba($black, .2);


  $cursor-crosshair-value: crosshair;
  $cursor-default-value: default;
  $cursor-disabled-value: not-allowed;
  $cursor-pointer-value: pointer;
  $cursor-help-value: help;
  $cursor-text-value: text;
