






        @import '_foundation-hacks.scss';

        @import '_centering.scss';

        @import '_bottom-arrow.scss';

        @function spa-strip-unit($value) {
            @return $value / ($value * 0 + 1);
        }

        @mixin spa-bounce($range) {
            0% { margin-top: 0; }
            50% { margin-top: $range; }
            100% { margin-top: 0; }
        }







        @mixin spa-fontsize($fontsize, $designsize) {
          $rems: spa-strip-unit($fontsize / $rem-base);
          $vm: spa-strip-unit(100 * $fontsize / $designsize);

          font-size: #{$rems}rem;
          font-size: #{$vm}vm; 
          font-size: #{$vm}vmin;
        }
















        @mixin spa-aspect-ratio($width, $height) {
          position: relative;

          &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: ($height / $width) * 100%;
          }

          > .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
