





$black: #000;
$white: #fff;
$mid-gray: #666;
$dark-gray: #333;
$light-gray: #ccc;








$brand-dawn-red: #FBDDD4;
$brand-bright-red: #DC1431;
$brand-regal-red: #C41230;
$brand-crimson-red:#8A2A2B;
$brand-dark-red: #780032;


$color-royal-one: #0053c2; 
$brand-day-blue: #D9F2FA;
$brand-lightest-blue: #00A9E0; 
$brand-light-blue: #009CDE; 
$brand-bright-blue: #0073CF;
$brand-royal-blue: #0052C2;
$brand-dark-blue: #012169;
$brand-night-blue: #000A23;


$color-gray-s05: #F5F5F5; 
$brand-pale-gray-light: #F2F2F2;
$brand-pale-gray: #E3E3E3;
$brand-gray-light: #D3D3D3;
$brand-gray: #A8A8A8;
$brand-gray-dark: #787878;
$brand-copy-gray-1: #595959;
$brand-copy-gray-2: #414141;


$sb-pale-gray: #ECE7DD;
$sb-pale-gray-light: #F9F7F4;
$sb-dark-gray: #857363;
$sb-warm-gray-light: #D1C9C0;


$prt-gold: #E0A526;
$prt-platinum: #828282;
$prt-platinum-honors: #000000;











$red-primary: $brand-bright-red;
$red-secondary: $brand-regal-red;
$red-tertiary: $brand-dark-red;
$red-quarternary: $brand-crimson-red;


$red-violet-primary: #aa0061;
$red-violet-secondary: #840b55;
$red-violet-tertiary: #51284f;


$blue-primary: $brand-bright-blue;
$blue-secondary: $brand-royal-blue;
$blue-tertiary: $brand-dark-blue;
$blue-quarternary: $brand-light-blue;
$blue-quinary: #ecf6fd;
$blue-senary: #b6dcfc;
$blue-septenary: $brand-night-blue;


$light-gray-primary: #f9f7f4;
$light-gray-secondary: #f3efe7;
$light-gray-tertiary: #ece6dd;
$light-gray-quarternary: #d1c9c0;
$light-gray-quinary: #f1efec;
$light-gray-senary: #dad3cd;
$light-border-gray: #cfc7bd;
$footer-gray: #534a40;


$cool-gray-primary: $brand-pale-gray-light;
$cool-gray-secondary: $brand-gray-light;
$cool-gray-tertiary: $brand-copy-gray-1;
$cool-gray-quarternary: $brand-copy-gray-2;
$cool-gray-quinary: #828282;


$brown-primary: #857363;
$brown-secondary: #6b5e51;
$brown-tertiary: #524940;
$brown-quaternary: #777766;
$brown-quinary: #A39382;
$brown-senary: #9d8e80;


$orange-primary: #ea7600;
$orange-secondary: #b94700;
$orange-tertiary: #73381d;


$purple-primary: #673bb8;
$purple-secondary: #5c068c;
$purple-tertiary: #4b116f;


$green-primary: #19ac5c;
$green-secondary: #13884a;
$green-tertiary: #00ad50;
$green-quaternary: #007749;
$green-quinary: #154734;


$yellow-primary: #f2a900;
$yellow-secondary: #cc8a00;
$yellow-tertiary: #73531d;
$yellow-quaternary: #e0a526;


$light-yellow: #faf5e3;
$orange: #fbe6ac;
$light-pink: #fff4f9;









$bank-primary-red: $red-primary;
$bank-regal-red: $red-secondary;
$bank-dark-red: $red-tertiary;

$bright-red: $red-primary;
$regal-red: $red-secondary;
$dark-red: $red-tertiary;
$crimson-red: $red-quarternary;


$bank-primary-blue: $blue-primary;
$bank-medium-blue: $blue-secondary;
$bank-dark-blue: $blue-tertiary;
$bank-edge-blue: $blue-quarternary;
$bank-lightest-blue: $blue-quinary;
$bank-light-blue: $blue-senary;

$lightest-blue: $blue-quinary; 
$light-blue: $blue-senary; 
$bright-blue: $blue-primary;
$royal-blue: $blue-secondary;
$dark-blue: $blue-tertiary;
$night-blue: $blue-septenary;


$bank-dark-green: $green-primary;
$bank-light-green: $green-secondary;

$bright-green: $green-tertiary;
$green: $green-quaternary;
$dark-green: $green-quinary;


$bright-orange: $orange-primary;
$orange: $orange-secondary; 
$dark-orange: $orange-tertiary;


$bright-purple: $purple-primary;
$purple: $purple-secondary;
$dark-purple: $purple-tertiary;


$bright-crimson: $red-violet-primary;
$crimson: $red-violet-secondary;
$dark-crimson: $red-violet-tertiary;


$bank-alert-yellow: $light-yellow;
$bank-alert-orange: $orange;
$bank-error-pink: $light-pink;


$bank-black: $black;
$bank-white: $white;
$bank-light-gray: $light-gray;
$bank-mid-gray: $mid-gray;
$bank-dark-gray: $dark-gray;
$bank-border-gray: $light-border-gray;
$bank-footer-gray: $footer-gray;


$bank-brown-primary: $brown-primary;
$bank-brown-secondary: $brown-secondary;
$bank-brown-tertiary: $brown-tertiary;
$bank-brown-quaternary: $brown-quaternary;
$bank-brown-quinary: $brown-quinary;


$bright-yellow: $yellow-primary;
$yellow: $yellow-secondary;
$dark-yellow: $yellow-tertiary;


$pale-gray-light: $light-gray-primary;
$warm-gray-15-tint: $light-gray-quinary; 
$warm-gray-40-tint: $light-gray-senary; 
$pale-gray: $light-gray-tertiary;
$warm-gray-light: $light-gray-quarternary;
$warm-gray: $brown-senary;
$body-copy-gray: $dark-gray;
$dark-gray: $brown-primary;
$copy-gray-1: $brown-secondary;
$copy-gray-2: $brown-tertiary;


$cool-gray-light: $cool-gray-primary;
$cool-gray: $cool-gray-secondary;
$cool-gray-medium: $cool-gray-tertiary;
$cool-gray-dark: $cool-gray-quarternary;


$gold: $yellow-quaternary;
$platinum: $cool-gray-quinary;
$platinum-honors: $black;


$bank-light-gray-primary: $light-gray-primary;
$bank-light-gray-secondary: $light-gray-secondary;
$bank-light-gray-tertiary: $light-gray-tertiary;
$bank-light-gray-quarternary: $light-gray-quarternary;
