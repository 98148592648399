
  $columns: 12;

  $max-width: 1296px;

  $small-gutters: 10px;
  $medium-gutters: 15px;
  $large-gutters: 20px;

  $small-gutter: $small-gutters / 2;
  $medium-gutter: $medium-gutters / 2;
  $large-gutter: $large-gutters / 2;

  $small-row-margin: $small-gutters * 2;
  $medium-row-margin: $medium-gutters * 2;
  $large-row-margin: $large-gutters * 2;
