







    @import '_foundation-vars.scss';

    @import '_breakpoints.scss';



    $sparta-include-html-global-classes: true;



    $sparta-base-font-size: 16px;
    $sparta-base-line-height: 20px;


    $body-text-color: $bank-dark-gray;
    $body-bg-color: $bank-white;

    $body-link-color: $bank-medium-blue;

    $input-text-color: $bank-black;

    $input-hint-text-color: $mid-gray;
    $input-hint-text-color-2: $brown-quaternary;

    $input-focus-text-color: $bank-dark-green;
    $input-focus-border-color: $bank-light-green;

    $input-error-background-color: $bank-error-pink;

    $help-layer-background-color: $bank-lightest-blue;
    $help-layer-border-color: $bank-light-blue;

    $alert-backgrond-color: $bank-alert-yellow;
    $alert-border-color: $bank-alert-orange;



    $spa-font-family-sans: sans-serif;

    $spa-font-size: 10px;
    $spa-line-height: 20px;

    $default-font-family: Arial, "Helvetica Neue", Helvetica, Roboto, $spa-font-family-sans;

    $spa-font-family: $default-font-family;



    $spa-font-weight-normal: normal !default;
    $spa-font-weight-bold  : bold !default;

    $spa-font: normal normal $spa-font-weight-normal $spa-font-size/$spa-line-height $spa-font-family;

    $spa-standard-transition-property: all;
    $spa-standard-transition-time: 0.3s;
    $spa-standard-transition-easing: ease;
    $spa-standard-transition-delay: 0.0s;

    $spa-standard-transition: $spa-standard-transition-property $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay;

    $link-color: $bank-medium-blue;

    $spa-link-color: $link-color;
    $spa-link-color-visited: $brand-royal-blue;
    $spa-link-color-hover: $bank-dark-blue;
    $spa-link-color-focus: $bank-dark-blue;


    $cnx-family: 'cnx-regular';
    $cnx-cond-family: 'cnx-regular-cond';
    $cnx-italic-family: 'cnx-italic';
    $cnx-italic-cond-family: 'cnx-italic-cond';
    $cnx-medium-family: 'cnx-medium';
    $cnx-medium-italic-family: 'cnx-medium-italic';
    $cnx-bold-family: 'cnx-bold';
    $cnx-bold-italic-family: 'cnx-bold-italic';
    $cnx-light-family: 'cnx-light';
    $cnx-light-italic-family: 'cnx-light-italic';
    $cnx-font-fallbacks: Arial, Helvetica, sans-serif;

    $regular-weight: 300;
    $light-weight: 200;
    $bold-weight: 700;


    $moduleNameSpace : dynamic-name-space;
